<template>
  <div class="dropdown"
      :style="dropdownStyle">
    <div class="selected"
      @click="open=!open">
      <div class="selected-text"
        :class="{ 'placeholder' : selectedText===undefined }"
        >{{ selectedText===undefined ? '선택' : selectedText }}</div>
      <i class="material-icons">
        {{ open ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
    </div>
    <div class="list"
      v-if="open">
      <div class="item"
        v-for="(item, idx) in options"
        :key="`option${idx}`"
        @click="clickItem(item)">
        {{ item[label_name] }}
        </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'dropdown',

  props: {
    label_name: {
      type: String,
      required: true
    },

    value_name: {
      type: String,
      required: true
    },

    value: {
      type: Number,
      required: true
    },

    options: {
      type: Array,
      required: true
    },

    // 드롭다운 리스트 width
    width: {
      type: Number,
      required: false
    },
    // 드롭다운 리스트 width (모바일 - 반응형 디자인)
    mobileStyle: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      val: this.value,
      open: false
    }
  },

  watch: {
    val(n) {
      this.$emit('update:value', n)
    }
  },

  computed: {
    selectedText() {
      let text = '';
      for(let i=0; i<this.options.length; i++) {
        if (this.options[i][this.value_name] === this.val) {
          text = this.options[i][this.label_name];
        }
      }
      return text;
    },

    dropdownStyle() {
      let deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;

      // 모바일
      if (deviceWidth<=600 && this.mobileStyle) {
        return this.mobileStyle;
      } else {
        return `width: ${this.width}px`;
      }
    }
  },

  methods: {
    clickItem(item) {
      this.val = item[this.value_name];
      this.open = false;
      this.$emit('select', item);
    }
  }


}
</script>

<style scoped>

  @media (max-width: 600px) {
    .dropdown {
      font-size: 16px;
      line-height: 32px;
      margin: 0;
    }
    .selected, .item {
      height: 36px;
    }
    .selected-text.placeholder{
      font-size: 14px;
    }    
    .list {
      top: 38px;
    }    
    .item {
      font-size: 14px;
    }
  }
  @media (min-width: 601px) {
    .dropdown {
      font-size: 24px;
      line-height: 40px;
      margin: 0 12px;
    }
    .selected, .item {
      height: 44px;
    }
    .selected-text.placeholder{
      font-size: 16px;
    }
    .list {
      top: 46px;
    }
    .item {
      font-size: 16px;
    }
  }

  .dropdown {
    /* font-size: 24px;
    line-height: 40px; */
    font-weight: 200;
    color: #303030;
    cursor: pointer;
    font-weight: 700;
    /* margin: 0 12px; */
    border-bottom: 2px solid #303030;
  }

  .selected {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /* .selected, .item {
    height: 44px;
  } */

  .selected-text {
    margin-right: 8px;
  }

  .selected-text.placeholder{
    color: #c7c7c7;
  }

  .list {
    position: absolute;
    width: 300px;
    background: #fff;
    padding: 12px 0;
    z-index: 1;
  }

  .item {
    /* font-size: 16px; */
    padding: 0 12px;
    color: #c7c7c7;
  }

  .item:hover {
    color: #303030;
  }

</style>
